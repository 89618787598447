import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import authProvider from '../../helpers/AuthProvider'

const drawerWidth = 200 // Adjust width as needed

const SideDrawer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname

  function handleLogout(): void {
    authProvider.logout().then(() => {
      navigate('/')
    })
  }

  const menuItems = [
    { text: 'Export', path: '/export' },
    { text: 'Eigenschaften Kategorien', path: '/eigenschaften-kategorien' },
    { text: 'Categories', path: '/categories' }
  ]

  const isActive = (path: string): boolean => currentPath === path

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#546160',
          color: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
      variant='permanent'
      anchor='left'>
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.path)}
              sx={{ backgroundColor: isActive(item.path) ? '#39424e' : 'inherit' }}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ marginTop: 'auto' }}>
        <Divider />
        <ListItem alignItems='center' disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: '#ffffff8a' }} />
            </ListItemIcon>
            <ListItemText primary={'Ausloggen'} />
          </ListItemButton>
        </ListItem>
      </Box>
    </Drawer>
  )
}

export default SideDrawer

import { AuthProvider } from '../../interfaces/authProvider'

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    if (username !== 'admin' || password !== 'admin') {
      return Promise.reject(new Error('Invalid credentials'))
    }
    localStorage.setItem('username', username)
    return Promise.resolve()
  },
  logout: () => {
    localStorage.removeItem('username')
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('username') ? Promise.resolve() : Promise.reject(new Error('Not authenticated'))
  },
  checkError: (error) => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem('username')
      return Promise.reject(new Error('Unauthorized'))
    }
    return Promise.resolve()
  },
  getIdentity: () => {
    return Promise.resolve({
      id: 'admin',
      fullName: 'Admin'
    })
  },
  getPermissions: () => Promise.resolve('')
}

export default authProvider

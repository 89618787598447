import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import authProvider from '../../helpers/AuthProvider'

const ProtectedRoute = ({ children }: any) => {
  const location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    authProvider
      .checkAuth()
      .then(() => {
        setIsAuthenticated(true)
        setIsLoading(false)
      })
      .catch(() => {
        setIsAuthenticated(false)
        setIsLoading(false)
      })
  }, [location])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return children
}

export default ProtectedRoute

import React, { useState } from 'react'
import { Button, Box, CircularProgress } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import axiosProvider from '../../helpers/AxiosHelper'

const Export = () => {
  const [loading, setLoading] = useState(false)


  const downloadFile = () => {
    setLoading(true)
    axiosProvider({
      url: 'getExportFile',
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'products.csv') // Setzen Sie den Dateinamen hier ein
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        console.error('Error downloading file:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2
      }}>
      <Button
        variant='contained'
        color='primary'
        startIcon={loading ? <CircularProgress color='inherit' size={24} /> : <FileDownloadIcon />}
        onClick={downloadFile}
        disabled={loading}
        sx={{ mt: 1, width: '220px' }}>
        Download Latest Data
      </Button>
    </Box>
  )
}

export default Export

import axios from 'axios'
import { environment } from '../../environment'

const axiosProvider = axios.create({
  baseURL: environment.backendUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  }
})

export default axiosProvider

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { } from 'react-router-dom'
import Export from './components/Export'
import Login from './components/Login/Login'
import ProtectedRoute from './components/ProtectedRoute'
import Layout from './components/Layout'
import CategoryPair from './components/CategoryPair'
import CategoryTransferPage from './components/CategoryFilter'
import { TaskStatusProvider } from './helpers/hooks/taskStatusHook'

const App = () => {
  return (
    <Router>
      <div className='App'>
        <TaskStatusProvider>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route
              path='/export'
              element={
                <Layout>
                  <ProtectedRoute>
                    <Export />
                  </ProtectedRoute>
                </Layout>
              }
            />
            <Route
              path='/eigenschaften-kategorien'
              element={
                <Layout>
                  <ProtectedRoute>
                    <CategoryPair />
                  </ProtectedRoute>
                </Layout>
              }
            />
            <Route
              path='/categories'
              element={
                <Layout>
                  <ProtectedRoute>
                    <CategoryTransferPage />
                  </ProtectedRoute>
                </Layout>
              }
            />

            <Route
              path='/'
              element={
                <Layout>
                  <ProtectedRoute>
                    <Export />
                  </ProtectedRoute>
                </Layout>
              }
            />
          </Routes>
        </TaskStatusProvider>
      </div>
    </Router>
  )
}

export default App

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axiosProvider from '../../helpers/AxiosHelper'

// Define the type for your task status
type TaskStatus = {
  isProcessing: boolean;
  lastUpdated: string | null;
  error: string | null;
};

const TaskStatusContext = createContext<TaskStatus>({ isProcessing: false, lastUpdated: null, error: null });

type TaskStatusProviderProps = {
  children: ReactNode;
};

export const TaskStatusProvider: React.FC<TaskStatusProviderProps> = ({ children }) => {
  const [taskStatus, setTaskStatus] = useState<TaskStatus>({
    isProcessing: false,
    lastUpdated: null,
    error: null
  });

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axiosProvider.get('task-status');
        setTaskStatus(response.data);
      } catch (error: any) {
        setTaskStatus((prevStatus) => ({ ...prevStatus, error: error.message }));
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 30000); // Fetch status every 30 seconds

    return () => clearInterval(interval);
  }, []);

  return <TaskStatusContext.Provider value={taskStatus}>{children}</TaskStatusContext.Provider>;
};

export const useTaskStatus = () => useContext(TaskStatusContext);

import React, { ReactNode } from 'react'
import { Box, CssBaseline, Toolbar } from '@mui/material'
import SideDrawer from '../SideDrawer'
import { useTaskStatus } from '../../helpers/hooks/taskStatusHook';

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { isProcessing, lastUpdated, error } = useTaskStatus();

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#24333b', color: 'white', minHeight: '100vh' }}>
      <CssBaseline />
      <SideDrawer />
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>Aufgabenstatus: {isProcessing ? 'In Bearbeitung' : 'Inaktiv'}</span>
          <span>Letzte Aktualisierung: {lastUpdated ? lastUpdated : 'Nicht verfügbar'}</span>
          {error && <span>Fehler: {error}</span>}
        </div>
        <Toolbar />
        {children}
        <div style={{ display: 'flex', position: 'absolute', bottom: 10 }}>
          Developed by KFM Digital Minds.{' '}
          <a target='_blank' rel='noopener noreferrer' style={{ color: 'rgb(44,168,86)' }} href='https://kfm-dm.de'>
            www.kfm-dm.de
          </a>
        </div>
      </Box>
    </Box>
  )
}

export default Layout

import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { Alert, AlertColor, CircularProgress, Container, Snackbar, Typography } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import axiosProvider from '../../helpers/AxiosHelper'

function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

interface CategoryData {
  id: number
  name: string
}

export default function CategoryTransferPage() {
  const [checked, setChecked] = React.useState<readonly string[]>([])
  const [left, setLeft] = React.useState<readonly string[]>([])
  const [right, setRight] = React.useState<readonly string[]>([])
  const [loading, setLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>('info');


  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  useEffect(() => {
    if (!left.length) {
      axiosProvider
        .get('categories')
        .then((res) => {
          handleOpenSnackbar('Datei erfolgreich heruntergeladen', 'success');
          setLeft(res.data.categories.map((p: CategoryData) => p.name))
        })
        .catch((error) => handleOpenSnackbar('Fehler beim Herunterladen der Datei', 'error'))

    }
  }, [])

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  const handleOpenSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const customList = (items: readonly string[]) => (
    <Paper sx={{ width: 200, height: 600, overflow: 'auto' }}>
      <List dense component='div' role='list'>
        {items.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`

          return (
            <ListItem key={value} role='listitem' button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )

  const downloadFile = () => {
    setLoading(true)
    axiosProvider({
      url: 'getFilteredExportFile',
      data: {
        categories: right
      },
      method: 'POST',
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'filtered_products.csv') // Setzen Sie den Dateinamen hier ein
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        console.error('Error downloading file:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container>
      <Typography variant='h4' mb={2}>
        Merkmals- und Kategorienzuordnung
      </Typography>
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        <Grid item>{customList(left)}</Grid>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Button
              sx={{
                my: 0.5,
                color: 'white',
                borderColor: 'white',
                '&.Mui-disabled': {
                  borderColor: '#ffffff6e',
                  color: '#ffffff6e',
                  opacity: 1
                }
              }}
              variant='outlined'
              size='small'
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label='move all right'>
              ≫
            </Button>
            <Button
              sx={{
                my: 0.5,
                color: 'white',
                borderColor: 'white',
                '&.Mui-disabled': {
                  borderColor: '#ffffff6e',
                  color: '#ffffff6e',
                  opacity: 1
                }
              }}
              variant='outlined'
              size='small'
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label='move selected right'>
              &gt;
            </Button>
            <Button
              sx={{
                my: 0.5,
                color: 'white',
                borderColor: 'white',
                '&.Mui-disabled': {
                  borderColor: '#ffffff6e',
                  color: '#ffffff6e',
                  opacity: 1
                }
              }}
              variant='outlined'
              size='small'
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label='move selected left'>
              &lt;
            </Button>
            <Button
              sx={{
                my: 0.5,
                color: 'white',
                borderColor: 'white',
                '&.Mui-disabled': {
                  borderColor: '#ffffff6e',
                  color: '#ffffff6e',
                  opacity: 1
                }
              }}
              variant='outlined'
              size='small'
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label='move all left'>
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(right)}</Grid>
      </Grid>

      <Button
        variant='contained'
        color='primary'
        startIcon={loading ? <CircularProgress color='inherit' size={24} /> : <FileDownloadIcon />}
        onClick={downloadFile}
        disabled={loading}
        sx={{ mt: 4, width: '220px' }}>
        Download File
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={1200}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  )
}
